import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    $('.dialogBox').dialog({ autoOpen: false, resizable: false, width: 700 })

    $('.dialogBox .close-button').on('click', function () {
      $('.dialogBox').dialog('close')
    })

    $('#flavorNatural').on('click', function () {
      $('#flavorNaturalModal').dialog('open')
    })

    $('#definitionNatural').on('click', function () {
      $('#definitionNaturalModal').dialog('open')
    })

    $('#flavorOrganic').on('click', function () {
      $('#flavorOrganicModal').dialog('open')
    })

    $('#definitionOrganic').on('click', function () {
      $('#definitionOrganicModal').dialog('open')
    })

    $('#flavorBiodynamic').on('click', function () {
      $('#flavorBiodynamicModal').dialog('open')
    })

    $('#definitionBiodynamic').on('click', function () {
      $('#definitionBiodynamicModal').dialog('open')
    })

    $('#flavorBiodynamicTurn').on('click', function () {
      console.log('clicked')
      $('#definitionBiodynamicModal').dialog('open')
    })
  }
}
